@tailwind base;
@tailwind components;

.nav-link {
  @apply font-semibold text-red-garnet transition duration-75 ease-in hover:opacity-75 p-2;
}

.primary-button {
  @apply font-semibold text-gray-50 bg-red-garnet transition ease-in duration-75 hover:opacity-75 px-4 py-2;
}

.section {
  @apply max-w-7xl mx-auto sm:px-2 xl:px-0;
}

.main-header {
  @apply font-bold text-red-garnet text-4xl text-center;
}

.section-divide {
  @apply divide-y px-2;
}

.section-formatting {
  @apply flex flex-col justify-center items-center space-y-2 py-9;
}

.section-grid-thirds {
  @apply grid grid-cols-1 md:grid-cols-3 w-full border rounded-lg bg-gray-100 divide-y md:divide-x md:divide-y-0 divide-gray-200;
}

.section-grid-halves {
  @apply grid grid-cols-1 md:grid-cols-2 w-full border rounded-lg bg-gray-100 divide-y md:divide-x md:divide-y-0 divide-gray-200;
}

.section-grid-element {
  @apply flex-1 flex flex-col justify-start items-center p-4;
}

.section-grid-element-title {
  @apply font-semibold text-gray-700 text-lg;
}

.section-grid-element-paragraph {
  @apply text-center text-gray-600 leading-relaxed;
}

.section-header {
  @apply font-bold text-red-garnet text-xl;
}

.section-subheading {
  @apply font-semibold text-gray-700 text-lg;
}

.section-description {
  @apply text-gray-700 md:tracking-normal leading-loose text-center;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-field {
  @apply mt-1 focus:ring-red-garnet focus:border-red-garnet block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
}

@tailwind utilities;
